import React from "react";
import locales from "../constants";
import { Layout, ContactFormDark } from "../components";
import { Link } from "gatsby";

const TestyAbPage = ({ location: { pathname } }) => {
    return (
        <Layout
            seo={{
                title: "Wdrożenie analityki",
                href: pathname,
                lang: "pl",
            }}
        >
            <span className="anchor" id="home"></span>
            <div className="subpage-header subpage-header--single-offer">
                <h1 className="subpage-header__title">
                    Wdrożenie <br /> <span>Analityki</span>
                </h1>
            </div>

            <div className="single-offer-menu-container">
                <div className="row">
                    <div className="col-lg-3">
                        <Link to="/uslugi/#analityka">
                            <div className="back-wrapper">
                                <span className="back-wrapper__arrow"></span>
                                <p className="back-wrapper__title">Usługi</p>
                            </div>
                        </Link>
                        <p className="nav-title">Specjalizacje:</p>
                        <ul>
                            <li>
                                <Link to="/konfiguracja-i-wdrożenie-google-analytics-4/">
                                    Konfiguracja i wdrożenie Google Analytics 4
                                </Link>
                            </li>
                            <li className="current">
                                <a href="#home">Wdrożenie analityki</a>
                            </li>
                            <li>
                                <Link to="/testy-ab-google-optimize/">
                                    Testy A/B
                                </Link>
                            </li>
                            <li>
                                <Link to="/analiza-strony-internetowej-przed-startem-kampanii-ppc/">
                                    Analiza strony internetowej przed startem
                                    kampanii PPC
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="single-offer-sections single-offer-sections--category-4">
                <section className="single-offer-section-text-left--cat-4 single-offer-section-text-left">
                    <div className="row">
                        <div className="col-xl-7 offset-xl-4 col-lg-9 offset-lg-3">
                            <div className="row align-items-center">
                                <div className="col-lg-11 text-col">
                                    <h2 className="text-col__title mb-40">
                                        Wdrożenie analityki internetowej
                                    </h2>
                                    <p className="text-col__desc mb-140">
                                        Nieważne czy posiadasz stronę
                                        internetową, która pełni funkcję
                                        wizytówki Twojej firmy, czy rozbudowany
                                        sklep internetowy z setkami tysięcy
                                        produktów. Każda z tych witryn powinna
                                        posiadać narzędzia zbierające dane o
                                        zachowaniu użytkowników, zbierać
                                        informacje na temat tego jak trafili na
                                        stronę oraz czy zrealizowali na niej
                                        zamierzone cele. Te informacje są
                                        niezbędne do prawidłowej analizy grupy
                                        docelowej oraz wszystkich prowadzonych
                                        działań marketingowych. Tylko właśnie
                                        dzięki narzędziom analitycznym oraz
                                        zaawansowanej analizie zebranych danych
                                        możliwa jest poprawna interpretacja
                                        wyników prowadzonych kampanii oraz ocena
                                        ich skuteczności oraz optymalizacja
                                        dalszych działań, co w efekcie przekłada
                                        się na pozyskanie większej liczby
                                        zapytań, sprzedaży czy obniżenie kosztów
                                        prowadzonych reklam poprzez eliminację
                                        gorzej konwertujących kampanii.
                                        <br />
                                        <br />
                                        Dzięki narzędziu Google Optimize możemy
                                        przetestować różne warianty witryny lub
                                        jej poszczególnych elementów, a
                                        następnie sprawdzić, czy zmiana ta
                                        wpłynęła na poprawę interesujących nas
                                        parametrów, takich jak klikalność
                                        buttonu, czas na stronie, wypełnienie
                                        formularza itp.
                                    </p>
                                    <img
                                        src={require("../assets/images/analityka-img.jpg")}
                                        alt=""
                                        className="img-fluid mb-140 center-img"
                                    />
                                    <h2 className="text-col__title mb-40">
                                        Prawidłowa analityka to:
                                    </h2>
                                    <ul className="big-bullets mb-140">
                                        <li>porządek w danych</li>
                                        <li>
                                            informacje na temat tego kim są
                                            użytkownicy Twojej strony
                                        </li>
                                        <li>
                                            szczegółowe ścieżki poruszania się
                                            użytkowników po witrynie
                                        </li>
                                        <li>
                                            wskazówki dotyczące użyteczności
                                            Twojej strony i tego czy wymaga
                                            zmian w kierunku lepszej
                                            intuicyjności poruszania się
                                        </li>
                                        <li>
                                            wstęp do tworzenia lejków
                                            sprzedażowych
                                        </li>
                                        <li>
                                            w przypadku e-commerce: raporty
                                            sprzedażowe ze wskazaniem m.in.
                                            danych demograficznych i
                                            geograficznych użytkowników
                                            dokonujących transakcji
                                        </li>
                                        <li>segmentacja użytkowników</li>
                                        <li>
                                            możliwość dokładnej analizy
                                            skuteczności prowadzonych działań
                                            marketingowych
                                        </li>
                                        <li>
                                            remarketing oparty na przemyślanych
                                            grupach remarketingowych
                                        </li>
                                    </ul>
                                    <h2 className="text-col__title mb-40">
                                        Narzędzia
                                    </h2>
                                    <p className="text-col__desc mb-100">
                                        Wdrożenie analityki obejmuje utworzenie,
                                        implementację w kodzie strony oraz pełną
                                        konfigurację takich narzędzi jak:
                                    </p>
                                    <div className="row">
                                        <div className="col-md-4 col-6">
                                            <img
                                                src={require("../assets/images/logo-gtm.jpg")}
                                                alt="Google Tag Manager"
                                                className="img-fluid mb-50 center-img"
                                            />
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <img
                                                src={require("../assets/images/logo-ga.jpg")}
                                                alt="Google Analytics"
                                                className="img-fluid mb-50 center-img"
                                            />
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <img
                                                src={require("../assets/images/logo-gsc.jpg")}
                                                alt="Google Search Console"
                                                className="img-fluid mb-50 center-img"
                                            />
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <img
                                                src={require("../assets/images/logo-fbpx.jpg")}
                                                alt="Facebook Pixel"
                                                className="img-fluid mb-50 center-img"
                                            />
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <img
                                                src={require("../assets/images/logo-hotjar.jpg")}
                                                alt="Hotjar"
                                                className="img-fluid mb-50 center-img"
                                            />
                                        </div>
                                        <div className="col-md-4 col-6">
                                            <img
                                                src={require("../assets/images/logo-yandex.jpg")}
                                                alt="Yandex Metrica"
                                                className="img-fluid mb-50 center-img"
                                            />
                                        </div>
                                    </div>
                                    <h2 className="text-col__title mb-40 mt-100">
                                        Konfiguracja
                                    </h2>
                                    <p className="text-col__desc mb-40">
                                        W zależności od charakteru Twojego
                                        biznesu oraz celów biznesowych
                                        skonfigurujemy dla Ciebie:
                                    </p>
                                    <ul className="big-bullets">
                                        <li>
                                            zaawansowane śledzenie transakcji
                                            e-commerce
                                        </li>
                                        <li>
                                            śledzenie określonych zdarzeń na
                                            stronie, np. przejście do zakładki
                                            kontakt, przesłanie formularza
                                            kontaktowego, pobranie PDF z planem
                                            mieszkania, wyświetlenie cennika czy
                                            kliknięcie w numer telefonu
                                            generujący połączenie
                                        </li>
                                        <li>
                                            nagrania poszczególnych sesji -
                                            wideo pokazujące dokładnie sposób
                                            poruszania się użytkownika (ruch
                                            myszką, kliknięcia)
                                        </li>
                                        <li>
                                            mapy ciepła (obrazuje które elementy
                                            użytkownicy najchętniej klikają)
                                        </li>
                                        <li>
                                            zbieranie list użytkowników, którzy
                                            odwiedzili stronę (do wykorzystania
                                            w kampanii remarketingowych)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="single-offer-section-contact">
                    <div className="form-wrapper">
                        <h2 className="single-offer-section-contact__title mb-40">
                            Skontaktuj się z nami! <br />
                            Wypełnij <span>formularz</span>
                        </h2>
                        <ContactFormDark locales={locales["pl"]} />
                    </div>
                    <img
                        className="img-fluid single-offer-section-contact__hand"
                        src={require("../assets/images/home-contact-hand-right.png")}
                        alt=""
                    />
                </section>
            </div>
        </Layout>
    );
};
export default TestyAbPage;
